//@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */
@import '@fontsource/material-icons';
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
//include mat.core();

// Define a dark theme
//$dark-theme: mat.define-theme((
//  color: (
//    theme-type: dark,
//    primary: mat.$violet-palette,
//  ),
//));
//
//// Define a light theme
//$light-theme: mat.define-theme((
//  color: (
//    theme-type: light,
//    primary: mat.$blue-palette,
//  ),
//));
//
//html {
//  // Apply the dark theme by default
//  @include mat.core-theme($dark-theme);
//  @include mat.button-theme($dark-theme);
//
//  // Apply the light theme only when the user prefers light themes.
//  @media (prefers-color-scheme: light) {
//    // Use the `-color` mixins to only apply color styles without reapplying the same
//    // typography and density styles.
//    @include mat.core-color($light-theme);
//    @include mat.button-color($light-theme);
//  }
//}

body {
  margin: 0;
  height: 93vh;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

content {
  //margin-top: 70px;
  margin: 70px 10px 0 10px;
  display: block;
  height: calc(100vh - 70px);
}

//.custom-button.mat-button-base {
//  @apply bg-blue-500 text-white; // Apply Tailwind CSS classes to customize button color
//}
.success {
  @apply bg-green-500;
}

.mdc-button {
  background: none;
}

.blocklyScrollbarVertical {
  display: none;
}
